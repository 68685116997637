<template>
  <v-app id="app" class="application_style">
    <v-navigation-drawer
      ref="navigationBar"
      v-model="drawer"
      v-if="!$route.meta.hideMainSidebar && smAndUpVal"
      app
      :mini-variant-width="80"
      :mini-variant.sync="sidebarMini"
      :width="sidebarMini ? 80 : 325"
      :permanent="(isPermanent && !drawer) || smAndUpVal"
      :temporary="isPermanent && drawer"
      id="navigation-bar"
      :data-test="role == 'admin' ? 'adminSidebarItem' : 'sidebarItem'"
    >
      <v-sheet @click="sideToggleMenu" class="fill-height">
        <SideBar
          :sidebarOption="role == 'admin' ? adminSidebarItem : sidebarItem"
          :expandOnhover="false"
          :isMini="sidebarMini"
        ></SideBar>
        <div class="toggle-button">
          <Button
            v-if="!$route.meta.secondSidebar"
            @onClick="toggleMenu"
            size="small"
            variantTypes="hover"
            :plusIcon="!sidebarMini ? 'collapseIcon.svg' : 'expandIcon.svg'"
            :style="sidebarMini ? { height: '26px', width: '26px' } : {}"
          />
        </div>
      </v-sheet>
    </v-navigation-drawer>
    <v-main
      data-testid="main-container"
      style="max-height: 100vh; overflow-y: scroll"
      class="d-flex justify-center ex-main-container"
    >
      <EmployeeSideBar
        v-if="
          $route.meta.secondSidebar && $route.meta.secondSidebar == 'employee'
        "
        :sidebarOption="employeeSidebar"
      />
      <router-view v-if="smAndUpVal" :key="$route.path"></router-view>

      <ExLoader v-if="isLoading" text="Loading..." :type="loaderType" />

      <div v-if="$vuetify.breakpoint.xsOnly" class="d-flex flex-column">
        <div class="mb-4 d-flex justify-center mt-8 mb-8">
          <img src="@/assets/login/logo.svg" />
        </div>
        <div class="desktop-only">Desktop only</div>
        <div class="only-desktop-message">
          Sorry, exiqtive.com only supports access via Desktops. No mobile
          support at this time
        </div>
      </div>
    </v-main>
  </v-app>
</template>

<script>
import SideBar from "@components/common/SideBar.vue";
import EmployeeSideBar from "@components/common/EmployeeSideBar.vue";
import Button from "@components/buttons/Button.vue";
import ExLoader from "@components/common/ExLoader.vue";
import { knock } from "@/plugins/knock";
import "vue-loading-overlay/dist/vue-loading.css";
import { createNamespacedHelpers } from "vuex";
import { getEmployeeById, getEmployeeChatter } from "@/api";
const { mapState: mapDataState } = createNamespacedHelpers("isPosition");

export default {
  name: "App",
  components: {
    SideBar,
    ExLoader,
    Button,
    EmployeeSideBar,
  },
  data: () => ({
    drawer: false,
    sidebarMini: false,
    toggleMenuClicked: false,
    secondEmpBar: false,
    loader: "spinner",
    isOtherSidebarPresent: false,
    role: "",
    adminSidebarItem: {
      type: "main",
      tItems: [
        {
          name: "ClientLogo",
          img: "clientlogo.svg",
          nameImg: "clientlogoname.svg",
          to: "",
        },
        {
          name: "Home",
          img: "home-01.svg",
          to: "/",
        },
        {
          name: "Accounts",
          img: "Company with Employees-grey.svg",
          to: "",
          count: "10",
        },
        {
          name: "Products",
          img: "box.svg",
          to: "",
          count: "10",
        },
        {
          name: "Subscriptions",
          img: "Subscription-grey.svg",
          to: "",
          count: "10",
        },
        {
          name: "Users",
          img: "users-01.svg",
          to: "",
          count: "10",
        },
        {
          name: "Partners",
          img: "Partnership-grey.svg",
          to: "",
          count: "10",
        },
      ],
      bItems: [
        {
          name: "Support",
          img: "life-buoy.svg",
          to: "",
        },
        {
          name: "Settings",
          img: "settings.svg",
          to: "",
        },
      ],
      cardItem: [
        {
          name: "Olivia Rhye",
          email: "olivia@untitledui.com",
          img: "Avatar.svg",
          to: "/login",
        },
      ],
    },
    idleTime: null,
    employeesData: [],
    totalChatterCount: 0,
  }),
  computed: {
    isLoading() {
      return this.$store.state.isLoading.isLoading;
    },
    loaderType() {
      return this.$store.state.isLoading.loaderType;
    },
    ...mapDataState(["activePositionsCount"]),
    sidebarItem() {
      const currentAccount = JSON.parse(
        localStorage.getItem("accessPermission")
      );
      const val = {
        type: "main",
        tItems: [
          {
            name: "ClientLogo",
            img: "clientlogo.svg",
            nameImg: "clientlogoname.svg",
            to: "",
          },
        ],
        bItems: [],
        cardItem: [],
      };
      const readinessItem = {
        name: "Readiness",
        img: "Readiness.svg",
        to: "",
        children: [
          {
            name: "Employees",
            img: "",
            to: "/readiness/employees",
          },
          {
            name: "Organization",
            img: "",
            to: "/readiness/organization",
          },
          {
            name: "Time to Readiness",
            img: "",
            to: "",
          },
          {
            name: "Catalogs",
            img: "",
            to: "/readiness/catalogs",
          },
        ],
      };
      const knowledgeItem = {
        name: "Knowledge",
        img: "Knowledge.svg",
        to: "",
        children: [
          {
            name: "Knowledge Catalog",
            img: "",
            to: "",
            count: 10,
          },
          {
            name: "Learning Catalog",
            img: "",
            to: "",
          },
        ],
      };
      const organizationItem = {
        name: "Organization",
        img: "Organization-chart.svg",
        to: "",
        children: [
          {
            name: "Employees",
            img: "",
            to: "/organization/employees",
            count: this.$store.getters["employeeStore/activeEmployeesCount"],
          },
          {
            name: "Positions",
            img: "",
            to: "/organization/positions",
            count: this.$store.getters["positionStore/activePositionsCount"],
          },
        ],
      };
      const performanceItem = {
        name: "Performance",
        img: "Performance.svg",
        to: "",
        children: [
          {
            name: "Scorecards",
            img: "",
            to: "",
            count: 10,
          },
          {
            name: "Tasks",
            img: "",
            to: "",
          },
          {
            name: "KPIs Catalog",
            img: "",
            to: "",
            count: 10,
          },
        ],
      };
      const compensationItem = {
        name: "Compensation",
        img: "Compensation.svg",
        to: "",
        children: [
          {
            name: "Employee Base Pay",
            img: "",
            to: "",
          },
          {
            name: "Position Pay Ranges",
            img: "",
            to: "",
            count: 10,
          },
          {
            name: "Pay Ranges",
            img: "",
            to: "",
          },
        ],
      };
      const settingsItem = {
        name: "Settings",
        img: "settings.svg",
        to: "/settings",
      };
      const employeeServiceItem = {
        name: "My Services",
        img: "Employee-portal.svg",
        to: "",
        children: [
          {
            name: "My Stuff",
            img: "",
            to: "/services/my-stuff",
          },
          {
            name: "Managing Others",
            img: "",
            to: "",
          },
          {
            name: "Be a Master/Coach",
            img: "",
            to: "/services/be-master-coach",
          },
        ],
      };

      if (currentAccount && currentAccount?.user) {
        val.cardItem.push({
          name: `${currentAccount?.user?.first_name} ${currentAccount?.user?.last_name}`,
          firstName: currentAccount?.user?.first_name,
          lastName: currentAccount?.user?.last_name,
          email: currentAccount?.user?.email,
          img: "",
          to: "/login",
        });
      }

      const isHidden = true; // according to the use case
      if (!isHidden) {
        val.tItems.push(knowledgeItem);
        val.tItems.push(performanceItem);
        val.tItems.push(compensationItem);
      }
      if (currentAccount && currentAccount?.has_employee) {
        val.tItems.push(employeeServiceItem);
      }

      if (
        currentAccount &&
        (currentAccount?.is_owner || currentAccount?.is_co_owner)
      ) {
        val.tItems.push(organizationItem);
      }

      if (
        (currentAccount && currentAccount?.is_owner) ||
        currentAccount?.is_co_owner ||
        currentAccount?.has_readiness_access
      ) {
        val.tItems.push(readinessItem);
      }
      if (
        (currentAccount && currentAccount?.is_owner) ||
        currentAccount?.is_co_owner
      ) {
        val.bItems.push(settingsItem);
      }

      return val;
    },
    employeeSidebar() {
      const empId = this.$route.params.id;
      const val = {
        type: "secondary",
        tItems: [
          {
            name: "Path to Readiness",
            img: "Readiness.svg",
            to: `/organization/employees/${empId}/pathtoreadiness`,
            count: "100%",
          },
          {
            name: "Chatter",
            img: "Chatter.svg",
            to: `/organization/employees/${empId}/chatter`,
            count: this.totalChatterCount,
          },
        ],
        cardItem: [
          {
            firstName: this.employeesData.first_name,
            lastName: this.employeesData.last_name,
            position: this.employeesData.title,
            email: "",
            photoUrl: this.employeesData.avatar,
            showAvailability: false,
            to: "/organization/employees",
            access: "has-access",
            status: this.employeesData.current_status,
          },
        ],
        empData: this.employeesData,
      };
      return val;
    },
    smAndUpVal() {
      return this.$vuetify.breakpoint.smAndUp;
    },
    lgAndUpVal() {
      return this.$vuetify.breakpoint.lgAndUp;
    },
    isPermanent() {
      return this.$vuetify.breakpoint.mdAndDown;
    },
  },
  async mounted() {
    this.role = this.$route.meta.role;
    await this.$store.dispatch("employeeStore/loadEmployees");
    await this.$store.dispatch("positionStore/loadPositions");
    if (this.$route.params) {
      const empId = this.$route.params.id;
      if (empId) {
        await this.getEmployees(empId);
      }
    }
    this.startIdleTimer();
    document.addEventListener("click", this.handleOutsideClick);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.handleOutsideClick);
  },
  watch: {
    drawer(val) {
      if (val && this.isPermanent) {
        document.addEventListener("click", this.handleClickOutside);
      } else {
        document.removeEventListener("click", this.handleClickOutside);
      }
    },
    $route: {
      handler(newVal) {
        this.secondEmpBar = !!newVal.meta.secondSidebar;
        this.isOtherSidebarPresent = this.$route.meta.sideBarPresent;
        this.role = this.$route.meta.role;
        if (newVal.path.includes("/pathtoreadiness")) {
          this.sidebarMini = true;
          this.drawer = false;
        } else {
          this.sidebarMini = false;
        }
      },
      deep: true,
      immediate: true,
    },
    "$vuetify.breakpoint.width"(newWidth) {
      if (this.$route.meta.secondSidebar == "employee") {
        this.sidebarMini = true;
        if (this.sidebarMini) {
          this.drawer = true;
        }
        this.secondEmpBar = false;
      } else {
        this.sidebarMini = newWidth < 1264;
        if (this.sidebarMini) {
          this.drawer = false;
        }
        this.secondEmpBar = false;
      }
    },
    "$route.params.id"(newId) {
      if (newId) {
        this.getEmployees(newId);
      }
    },
  },
  methods: {
    async getEmployeesChatter(accountID, empId) {
      const { data } = await getEmployeeChatter(accountID, empId, 1);
      if (data && data.count) {
        this.totalChatterCount = data.count;
      }
    },
    async getEmployees(empId) {
      const accountID = JSON.parse(localStorage.getItem("currentAccountID"));
      const { data } = await getEmployeeById(accountID, empId);
      if (data) {
        this.employeesData = data;
        this.getEmployeesChatter(accountID, empId);
      }
    },
    toggleMenu() {
      if (!this.isPermanent) {
        this.sidebarMini = !this.sidebarMini;
      } else {
        this.toggleMenuClicked = true;
        this.sidebarMini = !this.sidebarMini;
        if (this.sidebarMini === false) {
          this.drawer = true;
        } else {
          this.drawer = false;
        }
      }
      setTimeout(() => {
        this.$nextTick(() => {
          this.toggleMenuClicked = false;
        });
      }, 100);
    },
    sideToggleMenu() {
      if (this.isPermanent && !this.drawer && !this.toggleMenuClicked) {
        this.drawer = true;
        this.sidebarMini = false;
      }
    },
    handleOutsideClick(event) {
      if (this.isPermanent && this.drawer) {
        const navBarComponent = this.$refs.navigationBar;
        if (navBarComponent) {
          const navBarEl = navBarComponent.$el || navBarComponent;
          if (
            navBarEl &&
            navBarEl.contains &&
            !navBarEl.contains(event.target)
          ) {
            this.drawer = false;
            this.sidebarMini = true;
          }
        }
      }
    },
    startIdleTimer() {
      window.addEventListener("load", this.resetTimer, true);
      window.addEventListener("mousemove", this.resetTimer, true);
      window.addEventListener("mousedown", this.resetTimer, true);
      window.addEventListener("click", this.resetTimer, true);
      window.addEventListener("keydown", this.resetTimer, true);
      window.addEventListener("scroll", this.resetTimer, true);
    },
    resetTimer() {
      clearTimeout(this.idleTime);
      this.idleTime = setTimeout(this.logout, 600000);
    },
    logout() {
      if (this.$route.path != "/login") {
        localStorage.clear();

        knock.feeds.teardownInstances();
        knock.teardown();

        this.$router.push({ name: "Login" });
      }
    },
  },
};
</script>
<style>
.v-main__wrap {
  display: flex;
}
.application_style {
  font-family: "Inter" !important;
}
.ex-main-container {
  scrollbar-width: none;
}
.ex-main-container::-webkit-scrollbar {
  display: none;
}
.desktop-only {
  font-size: 36px;
  font-weight: 600;
  line-height: 44px;
  letter-spacing: -0.02em;
  text-align: center;
  color: #475467;
}
.only-desktop-message {
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  text-align: center;
  color: #475467;
}
.toggle-button {
  position: absolute;
  right: -14px;
  top: 43px;
  z-index: 9;
}
.toggle-button .plus-icon-small {
  top: 0px;
}
.v-navigation-drawer {
  overflow: visible;
}
.right-side-card {
  position: relative;
  top: 25px;
  left: -15px;
}
.employee-status-side {
  left: 120px;
  top: -10px;
}
.employee-back-icon {
  top: -29px;
}
.employee-three-dots {
  top: -27px;
}
</style>
