import { render, staticRenderFns } from "./SettingView.vue?vue&type=template&id=963f61e6&scoped=true"
import script from "./SettingView.vue?vue&type=script&lang=js"
export * from "./SettingView.vue?vue&type=script&lang=js"
import style0 from "./SettingView.vue?vue&type=style&index=0&id=963f61e6&prod&scoped=true&lang=css"
import style1 from "./SettingView.vue?vue&type=style&index=1&id=963f61e6&prod&scoped=true&lang=css"
import style2 from "./SettingView.vue?vue&type=style&index=2&id=963f61e6&prod&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "963f61e6",
  null
  
)

export default component.exports