<template>
  <v-container fluid>
    <StandarPageWithList
      title="Positions Organization"
      :hideActiveNo="true"
      :hideTopSection="true"
    >
      <template #action>
        <span></span>
      </template>
      <template #empty-content>
        <span></span>
      </template>
      <div class="ex-rolepage mb-8 mt-4" data-testid="ex-rolepage">
        <div class="mx-4 mb-4 d-flex justify-space-between">
          <ExBreadcrumbs
            class="custom-bread"
            :breadcrumbsList="computedBreadItems"
          />
          <div class="org-menu">
            <DropDownMenu
              :is-open="false"
              :dropdown-items="getReadinessCatalogsRoleItem(currentRole)"
              @onClick="
                handleReadinessOperations({
                  item: $event,
                  data: currentRole,
                })
              "
              :translateXOffset="12"
              :translateYOffset="16"
            >
              <template #activator>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z"
                    stroke="#344054"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M19 13C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11C18.4477 11 18 11.4477 18 12C18 12.5523 18.4477 13 19 13Z"
                    stroke="#344054"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M5 13C5.55228 13 6 12.5523 6 12C6 11.4477 5.55228 11 5 11C4.44772 11 4 11.4477 4 12C4 12.5523 4.44772 13 5 13Z"
                    stroke="#344054"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </template>
            </DropDownMenu>
          </div>
        </div>
        <div class="header d-flex justify-space-between mb-2 mx-4">
          <div class="d-flex align-start">
            <Icon class="mr-3" name="org_icon_role" />
            <StatusBadge
              :status="`${currentRole?.status || 'draft'}`"
              class="mr-3"
            />
            <OrgObjectCard
              :code="getOrgCodeName(currentRole)"
              :name="`${currentRole?.name || ''}`"
              :showIcon="false"
              :removeUnderlineToName="true"
            />
          </div>
          <div class="proficiency" :class="{ 'v-hidden': true }">
            <ExProficiencyWeight :proficiencyWeight="100" :sliderIcon="true" />
          </div>
        </div>
        <div class="menu">
          <RoleMenu
            :key="renderIndex"
            :items="items"
            :value="selectedTab"
            class="ex-menu-div"
            data-test="responsibility-menu"
            @onChange="handleTabChange"
          />
        </div>
        <div class="content">
          <v-tabs-items v-model="selectedTab" class="custom-tabs">
            <v-tab-item
              :transition="false"
              v-for="(item, index) in items"
              :key="index"
            >
              <v-card v-if="selectedTab == 2" elevation="0">
                <AttachedResponsibilitiesToARole
                  :table-data="filteredResponsibilities"
                  @onButtonAction="openAttachRes()"
                  @onSearchMode="$emit('onSearchMode', $event)"
                  class="role-to-res"
                  @detach="detachResponsibilities($event)"
                />
              </v-card>
              <v-card :elevation="0" v-if="selectedTab == 4">
                <ActivityStream
                  class="mx-8"
                  :data-stream="chatterData"
                  :show-load-more-button="totalChatterData > chatterData.length"
                  @onLoadMore="getChatterData(currentRole)"
                />
              </v-card>
              <v-card v-else></v-card>
            </v-tab-item>
          </v-tabs-items>
        </div>
      </div>
    </StandarPageWithList>
    <AttachedResponsibility
      v-model="attachedResDialog"
      @onSave="attachedRes"
      :items="resItems"
      @item-selected="handleItemSelected"
      @add-item-selected="addHandleItemSelected"
      :parent-name="currentRole?.name || ''"
    />
    <ActivateRole
      v-model="activateRoleDialog"
      @onSave="activateOrgRole"
      :role="currentRole"
    />
    <RenameRole
      v-model="showRenameRoleDialog"
      @onSave="renameOrgRole"
      :role="currentRole?.name || ''"
    />
    <DeleteRole
      v-model="deleteRoleDialog"
      @onDelete="deleteOrgRole"
      :role="currentRole?.name || ''"
    />
    <RetiredRole
      v-model="retiredRoleDialog"
      @onSave="retiredOrgRole"
      :role="currentRole"
    />
    <DeActivateRole
      v-model="deActivateRoleDialog"
      @onSave="deActivateOrgRole"
      :role="currentRole"
    />
    <ReActivateRole
      v-model="reActivateRoleDialog"
      @onSave="reActivateOrgRole"
      :role="currentRole"
    />
  </v-container>
</template>
<script>
import StatusBadge from "@components/organizations/statusBadge/Badge.vue";
import Icon from "@components/icons/Icon.vue";
import ExProficiencyWeight from "@components/ProficiencyWeight/ExProficiencyWeight.vue";
import RoleMenu from "@components/RoleMenu/RoleMenu.vue";
import OrgObjectCard from "@components/common/OrgObjectCard.vue";
import AttachedResponsibilitiesToARole from "@components/AttachedResponsibilitiesToARole/AttachedResponsibilitiesToARole.vue";
import ExBreadcrumbs from "@components/breadcrumbs/ExBreadcrumbs.vue";
import StandarPageWithList from "@components/common/PageHeaderWithButton.vue";
import ActivityStream from "@components/Chatter/ActivityStream.vue";
import { mapState } from "vuex";
import {
  getRole,
  getRoleChatter,
  attachResponsibilityToRole,
  createResponsibility,
  updateRole,
  updateRetiredRole,
  deleteReadinessRole,
  updateDeActiveRole,
  updateReActiveRole,
} from "@/api";
import AttachedResponsibility from "@components/RolesCatalog/AttachedRole.vue";
import dropdownMixin from "@components/DropDownMenu/dropdownMixin";
import roleMixin from "@components/views/mixins/roleMixin";
import DropDownMenu from "@components/DropDownMenu/DropDownMenu.vue";
import ActivateRole from "@components/RolesCatalog/ActivateRole.vue";
import RetiredRole from "@components/RolesCatalog/RetiredRole.vue";
import DeleteRole from "@components/RolesCatalog/DeleteRole.vue";
import RenameRole from "@components/RolesCatalog/RenameRole.vue";
import DeActivateRole from "@components/RolesCatalog/DeActivateRole.vue";
import ReActivateRole from "@components/RolesCatalog/ReActivateRole.vue";
export default {
  name: "RolePageView",
  components: {
    StatusBadge,
    Icon,
    ExProficiencyWeight,
    RoleMenu,
    OrgObjectCard,
    AttachedResponsibilitiesToARole,
    ExBreadcrumbs,
    StandarPageWithList,
    ActivityStream,
    AttachedResponsibility,
    DropDownMenu,
    ActivateRole,
    DeleteRole,
    RenameRole,
    RetiredRole,
    DeActivateRole,
    ReActivateRole,
  },
  mixins: [dropdownMixin, roleMixin],
  data() {
    return {
      renderIndex: 0,
      selectedTab: 2,
      items: [
        { title: "Assignments", count: null },
        { title: "Description", count: null },
        { title: "Attachments", count: null },
        { title: "Defaults", count: null },
        { title: "Chatter", count: null },
      ],
      currentRoleId: null,
      attachedResponsibilitiesIds: [],
      currentRole: null,
      chatterData: [],
      totalChatterData: 0,
      accountID: null,
      attachedResDialog: false,
      attachmentId: null,
      resItems: [],
      activateRoleDialog: false,
      retiredRoleDialog: false,
      deleteRoleDialog: false,
      showRenameRoleDialog: false,
      deActivateRoleDialog: false,
      reActivateRoleDialog: false,
    };
  },
  props: {
    dataStream: {
      type: Array,
      default: () => [
        {
          avatar: {
            picture: "",
            firstName: "Olivia",
            lastName: "Rhye",
          },
          IsExIQtiveBot: true,
          message: "Created",
          created_on: "1717459274000",
          IsHistory: true,
        },
        {
          avatar: {
            picture: "",
            firstName: "Olivia",
            lastName: "Rhye",
          },
          IsExIQtiveBot: true,
          message: "Created",
          created_on: "1717459202500",
          IsHistory: true,
        },
        {
          avatar: {
            picture: "https://cdn.vuetifyjs.com/images/john.jpg",
            firstName: "Olivia",
            lastName: "Rhye",
          },
          IsExIQtiveBot: true,
          message: "Created",
          created_on: "1717459206900",
          IsHistory: true,
        },
        {
          avatar: {
            picture: "https://cdn.vuetifyjs.com/images/john.jpg",
            firstName: "Olivia",
            lastName: "Rhye",
          },
          IsExIQtiveBot: false,
          message: "Created",
          created_on: "1717372803000",
          IsHistory: true,
        },
        {
          avatar: {
            picture: "",
            firstName: "Olivia",
            lastName: "Rhye",
          },
          IsExIQtiveBot: false,
          message: "Created",
          created_on: "1717372805000",
          IsHistory: true,
        },
        {
          avatar: {
            picture: "",
            firstName: "Olivia",
            lastName: "Rhye",
          },
          IsExIQtiveBot: false,
          message: "Created",
          created_on: "1717027200000",
          IsHistory: true,
        },
      ],
    },
  },
  computed: {
    ...mapState("readinessCatalogsStore", {
      storeResponsibilities: (state) => state.responsibilities,
    }),
    filteredResponsibilities() {
      return this.storeResponsibilities
        .filter((el) => this.attachedResponsibilitiesIds.includes(el.id))
        .map((el) => {
          const newEl = {};
          newEl.id = el.id;
          newEl.realId = el.id;
          newEl.parentId = -1;
          newEl.status = el.status;
          el.code = el.code + "";
          newEl.data = {
            type: "responsibility",
            ...el,
          };
          newEl.type = "responsibility";
          const typeCountRes = el.attachments.reduce((acc, att) => {
            acc[att.type] = (acc[att.type] || 0) + 1;
            return acc;
          }, {});
          el.attachments = Object.entries(typeCountRes).map(
            ([type, quantity]) => ({ type, quantity })
          );
          const totalCount = Object.values(el.attachments_count).reduce(
            (sum, count) => sum + count,
            0
          );
          newEl.attachedTo = {
            data: el.attachments,
          };
          newEl.assignment = totalCount;
          return newEl;
        });
    },
    computedBreadItems() {
      let items = [
        {
          text: "Readiness Catalogs",
          disabled: false,
          href: "/readiness/catalogs",
        },
        {
          text: "Roles",
          disabled: false,
          href: "/readiness/catalogs/roles",
        },
        {
          text: "Role",
          disabled: true,
        },
      ];
      if (this.$route.name === "OrgPositionDetailObjId") {
        items[0] = {
          text: "Positions organization",
          disabled: false,
          href: "/organization/positions",
        };
        items[1] = {
          text: "Roles attached",
          disabled: false,
          href: `/organization/positions/position/${this.$route.params.pId}`,
        };
        items[2] = {
          text: "Role",
          disabled: true,
        };
      }
      if (this.$route.name === "OrgPositionRolesResponse") {
        items[0] = {
          text: "Positions organization",
          disabled: false,
          href: "/organization/positions",
        };
        items[1] = {
          text: "Roles attached",
          disabled: false,
          href: `/organization/positions/position/${this.$route.params.pId}`,
        };
        items[2] = {
          text: "Responsibility",
          disabled: true,
        };
      }
      return items;
    },
  },
  async mounted() {
    this.accountId = JSON.parse(localStorage.getItem("currentAccountID"));
    this.currentRoleId = this.$route.params.objId;
    if (this.currentRoleId) {
      await this.getRoleAttachmentData(this.currentRoleId);
      await this.getChatterData(this.currentRole);
    }
  },
  watch: {
    attachedResponsibilitiesIds(newVal) {
      if (newVal) {
        const index = this.items.findIndex((el) => el.title == "Attachments");
        if (index > -1) {
          this.items[index].count = newVal.length;
        }
      }
    },
    filteredResponsibilities(newVal) {
      if (newVal) {
        const index = this.items.findIndex((el) => el.title == "Attachments");
        this.items[index].count = newVal.length;
        this.renderIndex++;
      }
    },
    totalChatterData(newVal) {
      if (newVal) {
        const index = this.items.findIndex((el) => el.title == "Chatter");
        this.items[index].count = newVal;
        this.renderIndex++;
      }
    },
  },
  methods: {
    async getRoleAttachmentData(roleId) {
      const { data } = await getRole(this.accountId, roleId);
      const attachments = data.attachments.filter((el) => {
        return el.type == "responsibility";
      });
      this.currentRole = data;
      await this.$store.dispatch("readinessCatalogsStore/loadRoles");
      await this.$store.dispatch("readinessCatalogsStore/loadResponsibilities");
      this.attachedResponsibilitiesIds = attachments.map((el) => el.id);
    },
    detachResponsibilities(data) {
      this.detachItems(data, async () => {
        await this.getRoleAttachmentData(this.currentRoleId);
      });
    },
    getOrgCodeName(currentRole) {
      if (currentRole?.code_str) {
        return currentRole.code_str.split(" ")[0];
      } else {
        return "#" + currentRole?.code;
      }
    },
    getResponsibility() {
      const attachedResponsibilityIds = new Set(
        this.currentRole.attachments
          .filter((attachment) => attachment.type === "responsibility")
          .map((attachment) => attachment.id)
      );
      const unmatchedResponsibilities = this.storeResponsibilities
        .filter((item) => !attachedResponsibilityIds.has(item.id))
        .filter((item) => item.status !== "retired");
      this.resItems = unmatchedResponsibilities.map((item) => ({
        id: item.id,
        code: item.code_str.match(/\d+/)[0],
        name: item.name,
        status: item.status,
      }));
    },
    openAttachRes() {
      this.attachedResDialog = true;
      this.getResponsibility();
    },
    handleItemSelected(data) {
      this.attachmentId = data.id;
    },
    async addResponsibility(name) {
      try {
        const payload = {
          name: name,
          account: this.accountId,
        };
        const newRes = await createResponsibility(this.accountId, payload);
        return newRes;
      } catch (e) {
        console.log("e: ", e);
      }
    },
    async addHandleItemSelected(item) {
      const { data } = await this.addResponsibility(item.name);
      this.attachmentId = data.id;
      await this.attachedRes();
      this.attachedResDialog = false;
    },
    async attachedRes() {
      try {
        const objId = this.currentRole.id;
        const payload = {
          attachment_id: this.attachmentId,
        };
        await attachResponsibilityToRole(this.accountId, objId, payload);
        await this.refreshData();
        this.attachedResDialog = false;
      } catch (e) {
        console.log("e: ", e);
      }
    },
    async refreshData() {
      const roleId = this.$route.params.objId;
      if (roleId) {
        const { data } = await getRole(this.accountId, roleId);
        this.currentRole = data;
        const attachments = data.attachments.filter((el) => {
          return el.type == "responsibility";
        });
        await this.$store.dispatch(
          "readinessCatalogsStore/loadResponsibilities"
        );
        this.attachedResponsibilitiesIds = attachments.map((el) => el.id);
      }
    },
    handleTabChange(tab) {
      this.selectedTab = tab;
    },
    async getChatterData(role) {
      const page = Math.ceil(this.chatterData.length / 10);
      const { data } = await getRoleChatter(this.accountId, role.id, page + 1);
      if (data && data.count) {
        this.totalChatterData = data.count;
      }
      if (data && data.results) {
        let chatterItems = data.results.map((el) => {
          const date = new Date(el.recorded_on);
          const milliseconds = date.getTime();
          const millisecondsString = milliseconds.toString();
          let nameParts = el.recorded_by_name.split(" ");
          const newEl = {};
          newEl.id = el.id;
          if (nameParts.length === 2) {
            newEl.avatar = {
              picture: "",
              firstName: nameParts[0],
              lastName: nameParts[1],
            };
          } else {
            newEl.avatar = {
              picture: "",
              firstName: el.recorded_by_name,
              lastName: "",
            };
          }
          newEl.IsExIQtiveBot = false;
          newEl.message = el.note;
          newEl.created_on = millisecondsString;
          newEl.IsHistory = el.is_history;
          return newEl;
        });
        this.chatterData.push(...chatterItems);
      }
    },
    handleReadinessOperations(operation) {
      switch (operation.item.value) {
        case "activate_role":
          this.activateRoleDialog = true;
          break;
        case "attach_responsibility_role":
          this.attachedResDialog = true;
          this.getResponsibility();
          break;
        case "retired_role":
          this.retiredRoleDialog = true;
          break;
        case "delete_role":
          this.deleteRoleDialog = true;
          break;
        case "rename_role":
          this.showRenameRoleDialog = true;
          break;
        case "de_activate_role":
          this.deActivateRoleDialog = true;
          break;
        case "re_activate_role":
          this.reActivateRoleDialog = true;
          break;
        default:
          break;
      }
    },
    closeDialog() {
      this.activateRoleDialog = false;
      this.retiredRoleDialog = false;
      this.deleteRoleDialog = false;
      this.showRenameRoleDialog = false;
      this.deActivateRoleDialog = false;
      this.reActivateRoleDialog = false;
    },
    async activateOrgRole() {
      try {
        const idRole = this.currentRole.id;
        const nameRole = this.currentRole.name;
        const payload = {
          name: nameRole,
          status: "active",
          account: this.accountId,
        };
        await updateRole(this.accountId, idRole, payload);
        this.activateRoleDialog = false;
        await this.refreshData();
      } catch (e) {
        console.log("e: ", e);
      }
    },
    async renameOrgRole(newName) {
      try {
        const idRole = this.currentRole?.id;
        if (!idRole) {
          throw "no id";
        }
        const payload = {
          name: newName,
          account: this.accountId,
        };
        await updateRole(this.accountId, idRole, payload);
        this.showRenameRoleDialog = false;
        await this.refreshData();
      } catch (e) {
        console.log("e: ", e);
      }
    },
    async deleteOrgRole() {
      try {
        const idRole = this.currentRole.id;
        await deleteReadinessRole(this.accountId, idRole);
        this.deleteRoleDialog = false;
        this.$router.push("/readiness/catalogs/roles");
      } catch (e) {
        console.log("e: ", e);
      }
    },
    async retiredOrgRole() {
      try {
        const idRole = this.currentRole.id;
        const nameRole = this.currentRole.name;
        const payload = {
          name: nameRole,
          status: "retired",
          account: this.accountId,
        };
        await updateRetiredRole(this.accountId, idRole, payload);
        this.retiredRoleDialog = false;
        await this.refreshData();
      } catch (e) {
        console.log("e: ", e);
      }
    },
    async deActivateOrgRole() {
      try {
        const idRole = this.currentRole.id;
        const nameRole = this.currentRole.name;
        const payload = {
          name: nameRole,
          status: "inactive",
          account: this.accountId,
        };
        await updateDeActiveRole(this.accountId, idRole, payload);
        this.deActivateRoleDialog = false;
        await this.refreshData();
      } catch (e) {
        console.log("e: ", e);
      }
    },
    async reActivateOrgRole() {
      try {
        const idRole = this.currentRole.id;
        const nameRole = this.currentRole.name;
        const payload = {
          name: nameRole,
          status: "inactive",
          account: this.accountId,
        };
        await updateReActiveRole(this.accountId, idRole, payload);
        this.reActivateRoleDialog = false;
        await this.refreshData();
      } catch (e) {
        console.log("e: ", e);
      }
    },
  },
};
</script>
<style scoped>
.ex-rolepage .proficiency {
  width: 145px;
}
.sliderWidthIcon {
  width: 100%;
}
</style>
<style>
.ex-rolepage .object-card-name {
  max-width: 274px !important;
}
.ex-rolepage .storybook-badge--active {
  width: 56px;
}
.ex-rolepage .stream-wrapper {
  box-shadow: none;
}
.role-to-res .storybook-button--btn-normal-hover-icon {
  font-size: 14px;
  line-height: 2;
}
.role-to-res .arrow-icon {
  top: 4px;
  position: relative;
  left: -3px;
}
.role-to-res img {
  position: relative;
  left: 10px;
}
</style>
