var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('div',{staticClass:"text-medium-emphasis emp-label-name"},[_vm._v(_vm._s(_vm.label)+"*")]),_c('v-select',{ref:"selectRef",staticClass:"employee-picker",attrs:{"outlined":"","disabled":_vm.disable,"menu-props":_vm.menuProps,"append-icon":_vm.isListVisible ? 'mdi-chevron-up' : 'mdi-chevron-down'},on:{"click:append":_vm.toggleDropdown,"focus":_vm.onInputFocus,"blur":_vm.onInputBlur},scopedSlots:_vm._u([(!_vm.selectedAccountingSubjects)?{key:"prepend-inner",fn:function(){return [_c('Icon',{attrs:{"name":"employee_search"}})]},proxy:true}:null,{key:"label",fn:function(){return [(_vm.isInputFocused)?_c('label',{staticClass:"label-class-emp"},[_vm._v(" Pick an "+_vm._s(_vm.label.toLowerCase())+" ")]):_vm._e(),(!_vm.isInputFocused && !_vm.selectedAccountingSubjects)?_c('label',{staticClass:"label-class-emp"},[_vm._v(" Pick an "+_vm._s(_vm.label.toLowerCase())+" ")]):_vm._e()]},proxy:true},{key:"append",fn:function(){return [(_vm.selectedAccountingSubjects)?_c('v-icon',{on:{"click":_vm.clearSelection}},[_vm._v(" mdi-close ")]):_vm._e()]},proxy:true}],null,true),model:{value:(_vm.selectedAccountingSubjects),callback:function ($$v) {_vm.selectedAccountingSubjects=$$v},expression:"selectedAccountingSubjects"}}),(_vm.isListVisible)?_c('v-list',{staticClass:"employee-custom-list",class:{
      'employee-custom-list-change': _vm.isInputFocused 
    }},[_c('v-list-item',[_c('div',{staticClass:"search-container-emp"},[_c('ExTextInput',{staticClass:"employee-search",attrs:{"ghostText":`Search an ${_vm.label.toLowerCase()}`,"placeholder":"","icon":"interface_search","showDownUpChevron":false},on:{"keydown":_vm.onKeyDown},model:{value:(_vm.searchText),callback:function ($$v) {_vm.searchText=$$v},expression:"searchText"}})],1)]),_c('div',{staticClass:"employee-list-view"},[_vm._l((_vm.filteredAccountingSubjects),function(item,index){return _c('v-list-item',{key:item.id,staticClass:"emp-badge-list",class:{ 'selected-item-emp': _vm.isSelected(item.id) },on:{"click":function($event){return _vm.selectItem(item)}}},[_c('Avatar',{staticClass:"list-avatar",attrs:{"size":"small","firstName":item.firstName,"lastName":item.lastName,"picture":_vm.isAvatar(item.avatar)}}),_c('v-list-item-content',{staticClass:"content-item-emp"},[_c('v-list-item-title',{staticClass:"content-title-emp"},[_c('div',{staticClass:"title-wrapper-emp"},[_c('div',{staticClass:"main-wrap"},[_c('span',[_vm._v("ABC"+_vm._s(item.fullName))]),_c('span',{staticClass:"item-id-emp"},[_vm._v(_vm._s(item.title))])]),_c('div',[(_vm.isSelected(item.id))?_c('img',{staticClass:"right-icon-emp",attrs:{"src":require('@components/assets/rightIcon.svg'),"alt":"icon"}}):_vm._e()])])])],1)],1)}),(_vm.filteredAccountingSubjects.length === 0)?_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"no-text"},[_vm._v(" Nothing matching your search ")])],1)],1):_vm._e()],2)],1):_vm._e(),_c('div',{staticClass:"selected-item-display",class:{
      'selected-item-dis': _vm.selectedAccountingSubjects && _vm.isListVisible,
      'selected-item-dis1': _vm.selectedAccountingSubjects && _vm.isListVisible && _vm.filteredAccountingSubjects.length === 1,
      'selected-item-dis2': _vm.selectedAccountingSubjects && _vm.isListVisible && _vm.filteredAccountingSubjects.length === 2,
      'selected-item-dis3': _vm.selectedAccountingSubjects && _vm.isListVisible && _vm.filteredAccountingSubjects.length === 0,
    },on:{"click":_vm.toggleMenu}},[(_vm.selectedAccountingSubjects)?[_c('Avatar',{attrs:{"size":"small","firstName":_vm.selectedEmployee.firstName,"lastName":_vm.selectedEmployee.lastName,"picture":_vm.isAvatar(_vm.selectedEmployee.avatar)}}),_c('div',{staticClass:"select-item-emp ml-20"},[_vm._v(" "+_vm._s(_vm.selectedEmployee.fullName)+" "),_c('span',{staticClass:"item-id-emp"},[_vm._v(_vm._s(_vm.selectedEmployee.title))])])]:_vm._e()],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }