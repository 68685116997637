var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ex-readiness-catalogs"},[_c('div',{staticClass:"mx-5"},[_c('CatalogsMenu',{attrs:{"items":_vm.menuItems,"value":_vm.selectedTab,"data-test":"reainess-catalog-menu"},on:{"onChange":function($event){return _vm.changeTab($event)}}})],1),_c('v-card',{staticClass:"rounded-0",attrs:{"flat":""}},[_c('div',{staticClass:"roles-container mt-3"},[_c('OrgObjectTreeList',{attrs:{"removeBorder":true,"topLevel":_vm.topLevel,"dropdownItems":_vm.dropdownItemsFunc,"pagination":_vm.pagination,"store-persist-filters":true,"store-filters-key-prefix":'readiness',"dataSource":_vm.sanitizedData,"allDataSource":_vm.allDataSource,"optionalColumns":[
                {
                  name: "dashboard",
                  caption: "Dashboard"
                },
                {
                  name: "attachedto",
                  caption: "Attached to"
                },
                {
                  name: "assignment",
                  caption: "Assignment"
                },
            ]},on:{"onRequest":function($event){return _vm.$emit('onRequest', $event)},"onButtonAction":function($event){return _vm.onAddAction(null)},"optionClick":function($event){return _vm.handleDropdownClick($event)},"onSearchMode":function($event){_vm.searchMode = $event},"appliedFilter":function($event){return _vm.appliedFilter($event)},"searchTextData":function($event){return _vm.searchTextData()},"openPopup":function($event){return _vm.$emit('openPopup', $event)},"detach":function($event){return _vm.$emit('detach', $event)}}})],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }