<template>
  <div class="badge-container" data-testid="badge-container">
    <ExResponsibilityBadgeHolder
      :items="menuItems"
      :badgeFrameItems="badgeFrameItems"
      :modalHeaderState="modalHeaderState"
      :currentResponse="currentResponse"
      :proficiencyValue="getWeight()"
      :breadItems="computedBreadItems"
      :dataStream="resChatter"
      :tasks="checklistTask"
      :totalChatterData="totalChatterData"
      @onButtonAction="openForceBadgeModal"
      @removeHolder="removeBadge($event)"
      @checkFormData="checkFormData($event)"
      @dropOption="checkDropdownOption"
      @optionClick="optionClick($event)"
      @requestMoreChatterData="getResponsiblityChatter"
      @onUpdateTask="changeCheckpoint"
      @optionOrgObjClick="handleReadinessOperations($event)"
      @get-chatter-list="getChatterList($event, 1)"
      :chatterList="chatterList"
      :totalCheckpointChatter="totalCheckpointChatter"
      @requestMoreCheckpointsChatter="getChatterList($event, chatterPage + 1)"
    />
    <EmployeeModal
      :dialog="showBadgeDialog"
      @onAction="addForceBadgeData($event)"
      @openEvalAction="openEvalResponse($event)"
      @back="backForceBadge"
      label="Employee"
      :name="currentResponse?.name"
      :items="employeeData"
      :activeCheckpoint="activeCheckPoints"
      :value="[1]"
      :empForceBadgeClass="true"
    />
    <confirmationDialog
      :dialog="showConfirmDialog"
      @confirm="confirmRemoveBadge"
      @cancel="cancelAddition"
    >
      <template v-slot:title>Remove Badge</template>
      <template v-slot:content>Are you sure you want to remove ?</template>
    </confirmationDialog>
    <ExDeleteCheckpoint
      v-model="showDeleteDialog"
      :name="checkpointData?.criteria"
      @onAction="deleteCheckpoint(checkpointId)"
    />
    <ActivateCheckpoint
      v-model="showActivateCheckpointDialog"
      :checkpoint="currentCheckPoint?.criteria || ''"
      @onSave="activateCheckpoint"
    />
    <RetireCheckpoint
      v-model="showRetireCheckpointDialog"
      :checkpoint="currentCheckPoint?.criteria || ''"
      @onSave="retireCheckpoint"
    />
    <ReActivateCheckpoint
      v-model="showReActivateCheckpointDialog"
      :checkpoint="currentCheckPoint?.criteria || ''"
      @onSave="reActivateCheckpoint"
    />
    <EvalResponsibilityModal
      :dialog="openEvalResponsibility"
      :badges="badges"
      :checkpointsData="checkpointsData"
      :evalModalHeaderState="evalModalHeaderState"
      :evalModalEmpState="evalModalEmpState"
      @closeModal="openEvalResponsibility = false"
    />
    <RenameResponsibility
      v-model="showRenameResponsibilityDialog"
      @onSave="renameOrgResponsibility($event)"
      :responsibility="currentOrgResponsibility?.data?.name || ''"
    />
    <DeleteResponsibility
      v-model="deleteResponsibilityDialog"
      :responsibility="currentOrgResponsibility?.data?.name || ''"
      @onDelete="deleteOrgResponsibility"
    />
    <ActivateResponsibility
      v-model="showActivateResponsiblityDialog"
      :responsibility="currentOrgResponsibility?.data?.name || ''"
      @onSave="activateOrgResponsibility"
    />
    <RetireResponsibility
      v-model="showRetireResponsibilityDialog"
      :responsibility="currentOrgResponsibility?.data?.name || ''"
      @onSave="retireOrgResponsibility"
    />
    <DeActivateResponsibility
      v-model="showDeActivateResponsiblityDialog"
      :responsibility="currentOrgResponsibility?.data?.name || ''"
      @onSave="deActivateOrgResponsibility"
    />
    <ReActivateResponsibility
      v-model="showReActivateResponsiblityDialog"
      :responsibility="currentOrgResponsibility?.data?.name || ''"
      @onSave="reActivateOrgResponsibility"
    />
  </div>
</template>
<script>
import ExResponsibilityBadgeHolder from "@components/ResponsibilityBadgeHolder/ExResponsibilityBadgeHolder.vue";
import ExDeleteCheckpoint from "@components/DeleteCheckpointPopup/DeleteCheckpoint.vue";
import ActivateCheckpoint from "@components/RolesCatalog/ActivateCheckpoint.vue";
import RetireCheckpoint from "@components/RolesCatalog/RetireCheckpoint.vue";
import ReActivateCheckpoint from "@components/RolesCatalog/ReActivateCheckpoint.vue";
import EvalResponsibilityModal from "@components/EvalResponsibilityModal/EvalResponsibilityModal.vue";
import { responsibilityStateUpdate, deleteResponsibility } from "@/api";
import RenameResponsibility from "@components/RolesCatalog/RenameResponsibility.vue";
import DeleteResponsibility from "@components/RolesCatalog/DeleteResponsibility.vue";
import ActivateResponsibility from "@components/RolesCatalog/ActivateResponsibility.vue";
import RetireResponsibility from "@components/RolesCatalog/RetireResponsibility.vue";
import DeActivateResponsibility from "@components/RolesCatalog/DeActivateResponsibility.vue";
import ReActivateResponsibility from "@components/RolesCatalog/ReActivateResponsibility.vue";
import responsibilityMixin from "@components/views/mixins/responsibilityMixin";
import roleMixin from "@components/views/mixins/roleMixin";
export default {
  name: "ResBadgeHolderView",
  components: {
    ExResponsibilityBadgeHolder,
    ExDeleteCheckpoint,
    ActivateCheckpoint,
    RetireCheckpoint,
    ReActivateCheckpoint,
    EvalResponsibilityModal,
    RenameResponsibility,
    DeleteResponsibility,
    ActivateResponsibility,
    RetireResponsibility,
    DeActivateResponsibility,
    ReActivateResponsibility,
  },
  mixins: [responsibilityMixin, roleMixin],
  data() {
    return {
      showBadgeDialog: false,
      showConfirmDialog: false,
      currentResponse: null,
      modalHeaderState: null,
      dataResponsibilitiesBadge: [],
      employeeData: [],
      employeeFilterData: [],
      badgeFrameItems: [],
      menuItems: [
        { title: "Checklist", count: 0 },
        { title: "Badge Holders", count: 0 },
        { title: "Evaluation", count: 0 },
        { title: "Assignments", count: 0 },
        { title: "Defaults", count: 0 },
        { title: "Chatter", count: 0 },
      ],
      badgeData: null,
      resChatter: [],
      paramType: null,
      checklistTask: [],
      showDeleteDialog: false,
      checkpointId: null,
      checkpointData: null,
      showActivateCheckpointDialog: false,
      showRetireCheckpointDialog: false,
      showReActivateCheckpointDialog: false,
      currentCheckPoint: null,
      totalChatterData: 0,
      activeCheckPoints: 0,
      openEvalResponsibility: false,
      badges: [
        {
          badge_id: "Apprentice",
          type: "Forced",
        },
        {
          badge_id: "Professional",
          is_force: true,
        },
        {
          badge_id: "Coach",
        },
        {
          badge_id: "Master",
          is_force: false,
        },
      ],
      checkpointsData: [],
      evalModalHeaderState: null,
      evalModalEmpState: null,
      currentOrgResponsibility: null,
      showRenameResponsibilityDialog: false,
      deleteResponsibilityDialog: false,
      showActivateResponsiblityDialog: false,
      showRetireResponsibilityDialog: false,
      showDeActivateResponsiblityDialog: false,
      showReActivateResponsiblityDialog: false,
      chatterList: [],
      totalCheckpointChatter: 0,
    };
  },
  async mounted() {
    this.paramType =
      this.$route.params?.type === "role" ? "role" : "responsibility";
    this.accountId = JSON.parse(localStorage.getItem("currentAccountID"));
    await this.loadResBadgeHolders(
      this.accountId,
      this.$route.params.objId,
      this.paramType
    );
  },
  computed: {
    chatterPage() {
      return Math.ceil(this.chatterList.length / 10);
    },
    computedBreadItems() {
      let items = [
        {
          text: "Readiness Catalogs",
          disabled: false,
          href: "/readiness/catalogs",
        },
        {
          text: "Responsibilities",
          disabled: false,
          href: "/readiness/catalogs/responsibilities",
        },
        {
          text: "Responsibility",
          disabled: true,
        },
      ];
      if (this.$route.name === "ReadinessCatalogsRolesResponse") {
        items[1] = {
          text: "Roles",
          disabled: false,
          href: "/readiness/catalogs/roles",
        };
      }
      if (this.$route.params.type === "roles") {
        items[1] = {
          text: "Roles",
          disabled: false,
          href: "/readiness/catalogs/roles",
        };
        items[2] = {
          text: "Role",
          disabled: true,
        };
      }
      if (this.$route.name === "OrgPositionDetailObjId") {
        items[0] = {
          text: "Positions organization",
          disabled: false,
          href: "/organization/positions",
        };
        items[1] = {
          text: "Roles attached",
          disabled: false,
          href: `/organization/positions/position/${this.$route.params.pId}`,
        };
        items[2] = {
          text: "Role",
          disabled: true,
        };
      }
      if (this.$route.name === "OrgPositionRolesResponse") {
        items[0] = {
          text: "Positions organization",
          disabled: false,
          href: "/organization/positions",
        };
        items[1] = {
          text: "Roles attached",
          disabled: false,
          href: `/organization/positions/position/${this.$route.params.pId}`,
        };
        items[2] = {
          text: "Responsibility",
          disabled: true,
        };
      }
      return items;
    },
  },
  methods: {
    openForceBadgeModal() {
      this.showBadgeDialog = true;
      this.activeCheckPoints = this.checklistTask.filter(
        (el) => el?.status && el?.status == "active"
      ).length;
    },
    backForceBadge() {
      this.showBadgeDialog = false;
    },
    removeBadge(badge) {
      this.showConfirmDialog = true;
      this.badgeData = badge;
    },
    cancelAddition() {
      this.showConfirmDialog = false;
    },
    getWeight() {
      if (
        this.currentResponse?.proficiency_weight > 0 &&
        this.currentResponse?.knowledge_weight === 0
      ) {
        return this.currentResponse?.proficiency_weight;
      } else if (
        this.currentResponse?.proficiency_weight === 0 &&
        this.currentResponse?.knowledge_weight > 0
      ) {
        return this.currentResponse?.knowledge_weight;
      }
      return 0;
    },
    checkDropdownOption(data) {
      this.checkpointData = data.data;
      if (data.item.name === "Delete") {
        this.showDeleteDialog = data.showDialog;
      } else if (data.item.name === "Activate") {
        this.showActivateDialog = data.showDialog;
      }
      this.checkpointId = data.data.id;
    },
    optionClick(operation) {
      this.currentCheckPoint = JSON.parse(JSON.stringify(operation.data));
      switch (operation.item.value) {
        case "activate_checkpoint":
          this.showActivateCheckpointDialog = true;
          break;
        case "retire_checkpoint":
          this.showRetireCheckpointDialog = true;
          break;
        case "reactivate_checkpoint":
          this.showReActivateCheckpointDialog = true;
          break;
        default:
          break;
      }
    },
    handleReadinessOperations(operation) {
      switch (operation.item.value) {
        case "rename_responsibility":
          this.showRenameResponsibilityDialog = true;
          break;
        case "delete_responsibility":
          this.deleteResponsibilityDialog = true;
          break;
        case "activate_responsibility":
          this.showActivateResponsiblityDialog = true;
          break;
        case "retire_responsibility":
          this.showRetireResponsibilityDialog = true;
          break;
        case "de_activate_responsibility":
          this.showDeActivateResponsiblityDialog = true;
          break;
        case "re_activate_responsibility":
          this.showReActivateResponsiblityDialog = true;
          break;
        default:
          break;
      }
    },
    closeDialog() {
      this.showRenameResponsibilityDialog = false;
      this.deleteResponsibilityDialog = false;
      this.showActivateResponsiblityDialog = false;
      this.showRetireResponsibilityDialog = false;
      this.showDeActivateResponsiblityDialog = false;
      this.showReActivateResponsiblityDialog = false;
    },
    async renameOrgResponsibility(newName) {
      try {
        const id = this.currentOrgResponsibility?.realId;
        if (id) {
          const payload = {
            name: newName,
            account: this.accountId,
          };
          await responsibilityStateUpdate(this.accountId, id, payload);
          this.currentOrgResponsibility = null;
          this.closeDialog();
          await this.loadResBadgeHolders(
            this.accountId,
            this.$route.params.objId,
            this.paramType
          );
        }
      } catch (e) {
        console.error("error: ", e);
      }
    },
    async activateOrgResponsibility() {
      try {
        const id = this.currentOrgResponsibility?.realId;
        const name = this.currentOrgResponsibility?.data?.name;
        if (!id || !name) {
          throw "data missing error";
        }
        const payload = {
          status: "active",
          name: this.currentOrgResponsibility?.data?.name,
          account: this.accountId,
        };
        await responsibilityStateUpdate(this.accountId, id, payload);
        this.closeDialog();
        await this.loadResBadgeHolders(
          this.accountId,
          this.$route.params.objId,
          this.paramType
        );
      } catch (e) {
        console.error("error: ", e);
      }
    },
    async retireOrgResponsibility() {
      try {
        const id = this.currentOrgResponsibility?.realId;
        const name = this.currentOrgResponsibility?.data?.name;
        if (!id || !name) {
          throw "data missing error";
        }
        const payload = {
          status: "retired",
          name: this.currentOrgResponsibility?.data?.name,
          account: this.accountId,
        };
        await responsibilityStateUpdate(this.accountId, id, payload);
        this.closeDialog();
        await this.loadResBadgeHolders(
          this.accountId,
          this.$route.params.objId,
          this.paramType
        );
      } catch (e) {
        console.log("error: ", e);
      }
    },
    async deleteOrgResponsibility() {
      try {
        const idRole = this.currentOrgResponsibility.realId;
        await deleteResponsibility(this.accountId, idRole);
        this.closeDialog();
        this.currentOrgResponsibility = null;
        this.$router.push("/readiness/catalogs/responsibilities");
      } catch (e) {
        console.error("e: ", e);
      }
    },
    async deActivateOrgResponsibility() {
      try {
        const id = this.currentOrgResponsibility?.realId;
        const name = this.currentOrgResponsibility?.data?.name;
        if (!id || !name) {
          throw "data missing error";
        }
        const payload = {
          status: "inactive",
          name: this.currentOrgResponsibility?.data?.name,
          account: this.accountId,
        };
        await responsibilityStateUpdate(this.accountId, id, payload);
        this.closeDialog();
        await this.loadResBadgeHolders(
          this.accountId,
          this.$route.params.objId,
          this.paramType
        );
      } catch (e) {
        console.error("error: ", e);
      }
    },
    async reActivateOrgResponsibility() {
      try {
        const id = this.currentOrgResponsibility?.realId;
        const name = this.currentOrgResponsibility?.data?.name;
        if (!id || !name) {
          throw "data missing error";
        }
        const payload = {
          status: "active",
          name: this.currentOrgResponsibility?.data?.name,
          account: this.accountId,
        };
        await responsibilityStateUpdate(this.accountId, id, payload);
        this.closeDialog();
        await this.loadResBadgeHolders(
          this.accountId,
          this.$route.params.objId,
          this.paramType
        );
      } catch (e) {
        console.error("error: ", e);
      }
    },
  },
  watch: {
    totalChatterData(newVal) {
      if (newVal) {
        const index = this.menuItems.findIndex((el) => el.title == "Chatter");
        this.menuItems[index].count = newVal;
      }
    },
  },
};
</script>
<style scoped>
.badge-container {
  width: 100%;
  padding: 25px;
}
</style>
