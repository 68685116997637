import { render, staticRenderFns } from "./ExOrgObjectActions.vue?vue&type=template&id=627a2525&scoped=true"
import script from "./ExOrgObjectActions.vue?vue&type=script&lang=js"
export * from "./ExOrgObjectActions.vue?vue&type=script&lang=js"
import style0 from "./ExOrgObjectActions.vue?vue&type=style&index=0&id=627a2525&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../account/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "627a2525",
  null
  
)

export default component.exports