import { render, staticRenderFns } from "./PrimaryPositionOccupationTag.vue?vue&type=template&id=00d496c1&scoped=true"
import script from "./PrimaryPositionOccupationTag.vue?vue&type=script&lang=js"
export * from "./PrimaryPositionOccupationTag.vue?vue&type=script&lang=js"
import style0 from "./PrimaryPositionOccupationTag.vue?vue&type=style&index=0&id=00d496c1&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../account/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "00d496c1",
  null
  
)

export default component.exports